import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';

import SEO from '../components/seo';
import Breadcrumbs from '../components/breadcrumbs';
import ImgGallery from '../components/img-gallery';
import SubscriptionForm from '../components/subscription-form';
import FAQ from '../components/faq';

import styles from '../styles/product.module.scss';
import buttonStyles from '../styles/buttons.module.scss';

const prepareImgs = (product) => (
	product.images.map((x, i) => ({
		key: i,
		img: x.src.childImageSharp.main,
		thumb: x.src.childImageSharp.thumb,
		ogimage: x.src.childImageSharp.ogimage,
		alt: x.alt
	}))
);

const JewelrySubscriptionPage = ({ data }) => {
	const subscription = data.allSubscriptionsJson.edges[0].node;

	const [imgs] = useState(prepareImgs(subscription));

	return (
		<>
			<SEO
				title='Become a Member'
				description={subscription.seoDescription}
				image={imgs.length ? imgs[0].ogimage.src : null}
				path={`/${subscription.handle}/`}
				jsonld={[
					{
						"@context": "https://schema.org/",
						"@type": "Product",
						"name": subscription.seoTitle,
						"image": imgs.map((x) => (data.site.siteMetadata.siteUrl + x.img.src)),
						"description": subscription.seoDescription,
						"brand": {
							"@type": 'Organization',
							"name": subscription.vendor
						},
						"offers": {
							"@type": "Offer",
							"url": data.site.siteMetadata.siteUrl + `/${subscription.handle}/`,
							"priceCurrency": "USD",
							"price": subscription.plans[0].firstMonth || subscription.plans[0].price,
							"priceValidUntil": moment().add(6, 'M').format('YYYY-MM-DD'),
							"itemCondition": "https://schema.org/NewCondition",
							"availability": "https://schema.org/InStock",
							"seller": {
								"@type": "Organization",
								"name": "Roselium"
							}
						}
					},
					{
						"@context": "https://schema.org",
						"@type": "FAQPage",
						"mainEntity": [
							{
								"@type": "Question",
								"name": "What if I don't like what I got?",
								"acceptedAnswer": {
									"@type": "Answer",
									"text": "Returns are quick and easy! We offer free returns on all subscription and store orders within 30 days of receipt. To initiate a return please contact us and we will get back to you within 24 hours."
								}
							},
							{
								"@type": "Question",
								"name": "What will I get in my shipment?",
								"acceptedAnswer": {
									"@type": "Answer",
									"text": "Every shipment contains one piece of handmade jewelry from our featured brand that month, conveniently stored in our reusable cotton pouch and environmentally friendly packaging."
								}
							},
							{
								"@type": "Question",
								"name": "When will my order ship?",
								"acceptedAnswer": {
									"@type": "Answer",
									"text": "Subscriptions ship at the beginning of every month. Subscribe before our next shipment is sent out! For example, if you subscribe on January 15th, your subscription order will be shipped out by February 4th."
								}
							},
							{
								"@type": "Question",
								"name": "Do you ship outside the us?",
								"acceptedAnswer": {
									"@type": "Answer",
									"text": "Shipping for our subscription is currently only available to the United States but we may offer shipping to Canada soon!"
								}
							},
							{
								"@type": "Question",
								"name": "What is the material of the jewelry?",
								"acceptedAnswer": {
									"@type": "Answer",
									"text": "All of our jewelry is made of lead-free and hypoallergenic materials such as 14k gold, gold filled, gold plated, and sterling silver."
								}
							},
							{
								"@type": "Question",
								"name": "How do I cancel my subscription?",
								"acceptedAnswer": {
									"@type": "Answer",
									"text": "You can cancel your subscription anytime in your account page. Under the Subscriptions section, you can click the cancel button to cancel your subscription. Subscription will remain active until the end of your billing cycle, and you can still use your member discount to shop in our store."
								}
							}
						]
					}
				]}
			/>
			<div className={styles.main}>
				<div className={styles.content}>
					<section className={styles.top}>
						<Breadcrumbs
							crumbs={[
								{
									name: 'Subscriptions',
									to: '/'
								},
								{
									name: subscription.title
								}
							]}
						/>
					</section>
					<section className={styles.product}>
						<div className={styles.photos}>
							<ImgGallery imgs={imgs} />
							<hr />
						</div>
						<div className={styles.info}>
							<SubscriptionForm subscription={subscription} />
						</div>
					</section>
					<hr />
				</div>
				<section className={styles.faq}>
					<div className={styles.content}>
						<h5>FREQUENTLY ASKED QUESTIONS</h5>
						<FAQ />
						<div className={styles.moreinfo}>
							<Button
								className={clsx(buttonStyles.default, styles.contactus)}
								variant='outlined'
								size='medium'
								onClick={() => window.zE('webWidget', 'open')}
							>
								STILL HAVE QUESTIONS? <br /> PLEASE CONTACT US!
                            </Button>
						</div>
					</div>
				</section>
			</div>
		</>
	);
};

export const query = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
        }
        
        allSubscriptionsJson(
            filter: {
                handle: { eq: "monthly-jewelry-subscription" }
            }            
        ) {
            edges {
                node {
                    id
                    title
                    seoTitle
                    handle
                    productType
                    vendor
                    descriptionHtml
                    seoDescription
                    plans {
                        id
                        title
                        description
                        interval
                        price
                        storeDiscount
                        promo
                        firstMonth
                        discountCode
                    }
                    images {
                        alt
                        src {
                            childImageSharp {
                                main: fluid(quality: 90, maxWidth: 4096) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                                thumb: fixed(quality: 90, width: 60) {
                                    ...GatsbyImageSharpFixed_withWebp_tracedSVG
                                }
                                ogimage: fixed(quality: 90, width: 1200, height: 630, cropFocus: CENTER) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                }
            }
        }                
    }
`;

export default JewelrySubscriptionPage;