import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    input: {
        padding: '0.875rem 0.875rem 0.75rem 0.875rem'
    }
}));


const VariantSelector = ({ value, option, onChange, className }) => {
    const inputLabel = useRef(null);
    const classes = useStyles();

    const [labelWidth, setLabelWidth] = useState(option.name.length * 7.5);

    useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl
            key={option.id}
            variant='outlined'
            classes={{ root: className }}
        >
            <InputLabel
                ref={inputLabel}
                htmlFor={option.name}
            >
                {option.name}
            </InputLabel>
            <Select
                value={value}
                onChange={onChange}
                input={
                    <OutlinedInput
                        labelWidth={labelWidth}
                        classes={{
                            input: classes.input
                        }}
                    />
                }
                inputProps={{
                    name: option.name,
                    id: option.id
                }}
            >
                {option.values.map(x => {
                    let value;
                    let display;

                    if (typeof (x) === 'object') {
                        value = x.id;
                        display = x.title;
                    } else if (typeof (x) === 'string') {
                        value = x;
                        display = x;
                    }

                    return (
                        <MenuItem
                            key={`${option.name}-${value}`}
                            value={value}
                        >
                            {display}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    )
};

VariantSelector.propTypes = {
    onChange: PropTypes.func,
    option: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
    }),
};

export default VariantSelector;
