import React, { useState } from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import FsLightbox from 'fslightbox-react';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { Maximize } from 'react-feather';

import '../styles/img-gallery.scss';

/* eslint-disable */

const prepareImgs = (imgs) => (
	imgs.map((x) => (x.img.src))
);

const ImgGallery = ({ imgs, tall }) => {
	const [sources] = useState(prepareImgs(imgs));

	const [fullscreen, setFullscreen] = useState(false);
	const [imgIndex, setImageIndex] = useState(0);
	const [swiping, setSwiping] = useState(false);

	const toggleFullscreen = () => {
		setFullscreen(value => (!value));
	};

	const openFullscreen = (i) => {
		if (!swiping) {
			setImageIndex(i);
			toggleFullscreen();
		}
	};

	const paging = (i) => (
		<a key={imgs[i].key}>
			<Img
				fixed={imgs[i].thumb}
				alt={imgs[i].alt}
				className='thumb'
			/>
		</a>
	);

	return (
		<>
			<Slider
				className={clsx('img-gallery', tall === true ? 'tall' : null)}
				customPaging={paging}
				arrows={false}
				dots={true}
				dotsClass={clsx('slick-dots', 'thumbs')}
				infinite={true}
				slidesToShow={1}
				slidesToScroll={1}
				rows={1}
				beforeChange={() => setSwiping(true)}
				afterChange={() => setSwiping(false)}
			>
				{imgs.map((x, i) => (
					<div
						key={x.key}
						className='photobox'
						onClick={(e) => {
							if (e && typeof (e.preventDefault) === 'function') {
								e.preventDefault();
							}

							if (e && typeof (e.stopPropagation) === 'function') {
								e.stopPropagation();
							}

							openFullscreen(i);
						}}
					>
						<Img
							fluid={x.img}
							alt={x.alt}
							className='photo'
						/>
						<IconButton
							type='button'
							aria-label='fullscreen'
							className='fullscreen'
							title='Fullscreen'
							onClick={(e) => {
								if (e && typeof (e.preventDefault) === 'function') {
									e.preventDefault();
								}

								if (e && typeof (e.stopPropagation) === 'function') {
									e.stopPropagation();
								}

								openFullscreen(i);
							}}
						>
							<Maximize />
						</IconButton>
					</div>
				))}
			</Slider>
			<FsLightbox
				toggler={fullscreen}
				sources={sources}
				sourceIndex={imgIndex}
				showThumbsOnMount={true}
			/>
		</>
	);
};

export default ImgGallery;