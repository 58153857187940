import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { ShoppingCart } from 'react-feather';
import { Link } from 'gatsby';
import _ from 'underscore';
import clsx from 'clsx';

import VariantSelector from './variant-selector';
import { formatCleanPrice } from '../utils/format-number';

import styles from '../styles/product.module.scss';

import shipping from '../images/icons/shipping.svg';
import refund from '../images/icons/refund.svg';
import clock from '../images/icons/clock.svg';
import guarantee from '../images/icons/guarantee.svg';

const SubscriptionForm = ({ subscription }) => {
    const [plan, setPlan] = useState(subscription.plans[0]);

    const [option] = useState({
        id: 'subscription-plan',
        name: 'Subscription Plan',
        values: subscription.plans
    });

    const onOptionChange = (event) => {
        const id = event.target.value;
        const plan = _.findWhere(subscription.plans, { id });
        setPlan(plan);
    };

    const SubscriptionPrice = () => {
        if (plan.promo) {
            return (
                <div className={clsx(styles.price, styles.promo)}>
                    <ins>{formatCleanPrice(plan.firstMonth)} <small>FIRST MONTH</small></ins>
                    <br />
                    <span className={styles.regularPrice}>{'then '}{formatCleanPrice(plan.price)}{' / month after'}</span>
                </div>
            );
        }

        return (
            <div className={styles.price}>
                <ins>{formatCleanPrice(plan.price)}{' '}<small>{'/ month'}</small></ins>
            </div>
        );
    }

    return (
        <>
            <small className={styles.type}>{subscription.productType}</small>
            <h2>{subscription.title}</h2>
            <small className={styles.vendor}>by <Link to='/about-us/'>{subscription.vendor}</Link></small>
            <SubscriptionPrice />
            <div className={styles.variants}>
                <div className={clsx(styles.variant, styles.lg)}>
                    <VariantSelector
                        value={plan.id}
                        option={option}
                        onChange={onOptionChange}
                        className={clsx(styles.selector, styles.lg)}
                    />
                </div>
                <div className={clsx(styles.variant, styles.descriptor)}>
                    {Boolean(plan.storeDiscount) &&
                        <p className={clsx(styles.description, styles.discount)}>Includes a {plan.storeDiscount}% discount to our store</p>
                    }
                    <p className={styles.description}>{plan.description}{'. Cancel anytime.'}</p>
                </div>
            </div>
            <Button
                variant='contained'
                color='primary'
                className={styles.checkout}
                fullWidth={true}
                component={Link}
                to={'/checkout/subscribe/'}
                state={
                    {
                        product_id: subscription.id,
                        plan_id: plan.id,
                        discount_code: plan.discountCode
                    }
                }
            >
                <ShoppingCart size={20} className={styles.icon} />
                CHECKOUT
            </Button>
            <p
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: subscription.descriptionHtml }}
            />
            <ul className={styles.perks}>
                <li>
                    <div className={styles.inner}>
                        <img src={shipping} alt='Free Shipping Icon' />
                        <small>Free Shipping</small>
                    </div>
                </li>
                <li>
                    <div className={styles.inner}>
                        <img src={refund} alt='Free Returns Icon' />
                        <small>Free Returns</small>
                    </div>
                </li>
                <li className={styles.break}></li>
                <li>
                    <div className={styles.inner}>
                        <img src={clock} alt='Cancel Anytime Icon' />
                        <small>Cancel Anytime</small>
                    </div>
                </li>
                <li>
                    <div className={styles.inner}>
                        <img src={guarantee} alt='Guarantee Icon' />
                        <small>100% Satisfaction</small>
                    </div>
                </li>
            </ul>
        </>
    )
};

SubscriptionForm.propTypes = {
    subscription: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        handle: PropTypes.string,
        productType: PropTypes.string,
        vendor: PropTypes.string,
        description: PropTypes.string,
        descriptionHtml: PropTypes.string,
        plans: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                title: PropTypes.string,
                description: PropTypes.string,
                interval: PropTypes.string,
                price: PropTypes.number,
                storeDiscount: PropTypes.number
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                alt: PropTypes.string,
                src: PropTypes.object,
            })
        )
    })
};

export default SubscriptionForm;
